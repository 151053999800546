<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="KeyIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('contaConfiguracao.binanceBtn') }}</span>
      </template>
      <conta-configuracao-binance-api />
    </b-tab>
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('contaConfiguracao.notificacaoBtn') }}</span>
      </template>
      <conta-configuracao-notification />
    </b-tab>
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('contaConfiguracao.trocarSenhaBtn') }}</span>
      </template>
      <conta-configuracao-password />
    </b-tab>
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="MonitorIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('contaConfiguracao.telaBtn') }}</span>
      </template>
      <conta-configuracao-tela />
    </b-tab>
    <b-tab v-if="$can(null, 'multinivel')">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LayoutIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('contaConfiguracao.landpageBtn') }}</span>
      </template>
      <conta-configuracao-landingpage />
    </b-tab>

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import ContaConfiguracaoPassword from './ContaConfiguracaoPassword.vue'
import ContaConfiguracaoNotification from './ContaConfiguracaoNotification.vue'
import ContaConfiguracaoBinanceApi from './ContaConfiguracaoBinanceAPI.vue'
import ContaConfiguracaoTela from './ContaConfiguracaoTela.vue'
import ContaConfiguracaoLandingpage from './ContaConfiguracaoLandingpage.vue'

export default {
  components: {
    BTabs,
    BTab,
    ContaConfiguracaoPassword,
    ContaConfiguracaoNotification,
    ContaConfiguracaoBinanceApi,
    ContaConfiguracaoTela,
    ContaConfiguracaoLandingpage,
  },

}
</script>
