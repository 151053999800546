<template>
  <div>
    <b-card>
      <b-card-text> {{ $t('contaConfiguracao.landingpage.descricao') }}</b-card-text>
      <b-overlay
        :show="loadingOverlay"
        rounded="sm"
      >
        <b-form
          @submit.prevent="submit"
        >
          <b-row>
            <b-col cols="12">
              <h6 class="mb-2">
                Landing Page: <b-link
                  :href="indicacao.linkLandpage"
                  target="_blank"
                >
                  {{ indicacao.linkLandpage }}
                </b-link>
              </h6>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group
                label="Whatsapp"
                label-for="whatsapp"
              >
                <b-form-input
                  v-model="data.whatsapp"
                  placeholder="+55 (XX) XXXXX-XXXX"
                  name="whatsapp"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group
                label="Whatsapp API"
                label-for="whatsapp-api"
              >
                <b-form-input
                  v-model="data.whatsappApi"
                  placeholder="https://web.whatsapp.com/send?phone=<NUMERO>&text=<MENSAGEM>"
                  name="whatsapp-api"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group
                label="Telegram"
                label-for="telegram"
              >
                <b-form-input
                  v-model="data.telegram"
                  placeholder="https://t.me/<USERNAME>"
                  name="telegram"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group
                label="Facebook Pixel"
                label-for="facebook-pixel"
              >
                <b-form-input
                  v-model="data.facebookPixel"
                  placeholder="<PIXEL>"
                  name="facebook-pixel"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1 mt-1"
                type="submit"
              >
                {{ $t('contaConfiguracao.salvarBtn') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import store from '@/store'
import {
  BButton, BRow, BCol, BCard, BCardText, BFormGroup, BFormInput, BForm, BOverlay, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import configuracaoContaStoreModule from './configuracaoContaStoreModule'

const CONFIGURACAO_CONTA_STORE_MODULE_NAME = 'configuracao-conta'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BForm,
    BOverlay,
    BLink,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      loadingOverlay: false,
      indicacao: null,
      data: {
        whatsapp: null,
        whatsappApi: null,
        telegram: null,
        facebookPixel: null,
      },
    }
  },

  created() {
    if (!store.hasModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)) store.registerModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME, configuracaoContaStoreModule)
    this.getIndicacao()
    this.getConfig()
  },

  beforeDestroy() {
    if (store.hasModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)) store.unregisterModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)
  },

  methods: {

    submit() {
      this.loadingOverlay = true
      this.$store.dispatch('configuracao-conta/setConfigLandingpage', this.data)
        .then(() => {
          this.$swal({
            title: 'Landing page alterada com sucesso',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }).catch(error => {
          this.$swal({
            title: 'Error!',
            text: error.response.data[0].erro,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }).finally(() => {
          this.loadingOverlay = false
        })
    },

    getIndicacao() {
      this.$store.dispatch('configuracao-conta/getIndicacao')
        .then(res => {
          this.indicacao = res.data
        })
    },

    getConfig() {
      this.$store.dispatch('configuracao-conta/getConfigLandingpage')
        .then(res => {
          this.data = res.data
        })
    },

    onHidden() {
      this.$refs.button.focus()
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
